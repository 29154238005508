<template>
    <div class="col-12 g">
        <div class="card card-body">
            <img :src="require('@/assets/images/loading.svg')" alt="" width="50" v-if="loading">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-sm table-striped table-bordered" id="headerTable">
                    <thead>
                        <th>
                            م
                        </th>
                        <th>
                            المستخدم
                        </th>
                        <th>
                            التاريخ
                        </th>
                        <th>
                            الموضوع
                        </th>
                        <th>
                            الخدمة
                        </th>
                        <th  v-if="type =='feedback'">
                            التقييم
                        </th>
                        <th>
                            الحالة
                        </th>
                        <th>
                            خيارات
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="(user, m) in users" :key="user._id" :class="user.unread > 0 ? 'bg-light-warning' : ''">
                            <td>{{ m+1 }}</td>
                            <td>
                                <span v-if="user.user">
                                    <span v-if="user.user._id">
                                        <a target="_blank" :href="'https://s.tahdir.net/autologin/' + user.user.jwt">
                                        <i class="fa fa-external-link"></i>
                                        {{user.user.name}} </a>
                                    </span>
                                </span>
                            </td>
                            <td>
                                {{ user.date }}
                            </td>
                            <td>
                                {{ user.title.substring(0,30) }}...
                            </td>
                            <td>
                                {{ user.service }}
                            </td>
                            <td v-if="type =='feedback'">
                                {{ user.rate }}
                            </td>
                            <td>
                                <span v-if="user.status == 0" class="text-danger">مفتوحة {{ user.unread }}</span>
                                <span v-if="user.status == 1">مغلقة</span>
                            </td>
                            <td>
                                <button class="bg-success text-white" @click="$router.push('/ticket/' + user._id)">
                                    فتح
                                    <i class="fa fa-arrow-left"></i>
                                </button>
                                <button class="bg-primary text-white" @click="current = user;" v-b-modal.up>
                                    <i class="fa fa-code"></i>
                                    برمجي
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <b-modal id="up" title="تدخل برمجي" hide-footer size="sm">
            <div class="form-group">
              <label for="">توضيح الفكرة بالتفصيل</label>
              <textarea class="form-control" rows="5" placeholder="اكتب هنا..." v-model="up_text"></textarea>
            </div>
            <input type="file"
                class="form-control" id="ingredient_file" @change="uploadImage()">
                <div class="col-12 text-center">
                    <br>
                    <button class="btn btn-success" @click="up()">
                        ارسال الآن
                    </button>
                </div>
        </b-modal>
    </div>
</template>

<script>
import { VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BDropdown,
        BDropdownItem,
        BModal,
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            loading: true,
            loading2: true,
            jwt: localStorage.getItem('jwt'),
            users: [],
            current: {},
            q: "",
            page: 0,
            services: [],
            selectedUser: {},
            type: window.location.href?.split('type/')[1]?.split("?")[0],
            int: null,
            up_text: "",
            up_file: ""
        }
    },
    beforeDestroy(){
        clearInterval(this.int)
    },
    created(){
        var g = this;
        g.gett();
        g.int = setInterval(() => {
            if(!window.location.href.includes("done")){
                window.location = window.location.href + '?done=r'
            }
        }, 1000);
    },
    methods: {
        up(){
            var g = this;
            $.post(api + '/admin/ticket/up', {
                jwt: this.jwt,
                id: this.current._id,
                up_text: this.up_text,
                up_file: this.up_file
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    $("#up___BV_modal_header_ > button").click()
                    g.gett()
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        uploadImage(){
            var g = this;
            var Upload = function (file) {
                this.file = file;
            };

            Upload.prototype.getType = function () {
                return this.file.type;
            };
            Upload.prototype.getSize = function () {
                return this.file.size;
            };
            Upload.prototype.getName = function () {
                return this.file.name;
            };
            Upload.prototype.doUpload = function () {
                var that = this;
                var formData = new FormData();
                g.uploading = true;
                // add assoc key values, this will be posts values
                formData.append("file", this.file, this.getName());
                formData.append("jwt", g.jwt);

                $.ajax({
                type: "POST",
                url: api + '/admin/upload',
                xhr: function () {
                    var myXhr = $.ajaxSettings.xhr();
                    if (myXhr.upload) {
                    myXhr.upload.addEventListener(
                        "progress",
                        that.progressHandling,
                        false
                    );
                    }
                    return myXhr;
                },
                success: function (data) {
                    // your callback here
                    g.uploading = false;
                    if(data.status != 100){
                        alert("هذا الملف غير مدعوم", 200)
                        return;
                    }
                    g.up_file = data.response
                },
                error: function (error) {
                    // handle error
                    alert("حدث خطأ");
                    g.uploading = false;
                },
                async: true,
                data: formData,
                cache: false,
                contentType: false,
                processData: false,
                timeout: 60000,
                });
            };
            var file = $("#ingredient_file")[0].files[0];
            var upload = new Upload(file);
            upload.doUpload();
        },
        gett(){
            var g = this;
            g.loading = true;
            $.post(api + '/admin/feedbacks', {
                jwt: this.jwt,
                type: window.location.href.split('type/')[1].split("?")[0],
                q: this.q,
                page: this.page,
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.users = r.response
                }
            }).fail(function(){
                g.loading = false;
            })
        },
    }
}
</script>
<style scoped>
</style>